.info-icon {
    margin-left: 5px;
    font-size: 1.2rem; /* Tamaño del icono */
    color: #007bff; /* Color del icono */
  }

.custom-tooltip-popper {
    background-color: #fff; /* Color de fondo del tooltip */
    border: 1px solid #ccc; /* Borde del tooltip */
    padding: 10px; /* Espaciado interno del tooltip */
    border-radius: 4px; /* Bordes redondeados */
    box-shadow: 0 2px 6px rgba(152, 51, 51, 0.15); /* Sombra del tooltip */
  }
  
  .custom-tooltip-popper:before {
    content: '';
    /*Crealaesquinaqueapuntaalicono*/
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px;
    border-color: transparent transparent #af0909 transparent; /*Colordefondodeltooltip*/
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}
  