@font-face {
  font-family: "gillsans";
  src: url("./assets/Fonts/gill_sans-webfont.woff") format("woff"),
    url("./assets/Fonts/gill_sans-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "gillsansMedium";
  src: url("./assets/Fonts/gill_sans_medium-webfont.woff") format("woff"),
    url("./assets/Fonts/gill_sans_medium-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "avenirMedium";
  src: url("./assets/Fonts/avenirltstd-roman-webfont.woff") format("woff"),
    url("./assets/Fonts/avenirltstd-roman-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "avenirLight";
  src: url("./assets/Fonts/avenirltstd-book-webfont.woff") format("woff"),
    url("./assets/Fonts/avenirltstd-book-webfont.woff2") format("woff2");
}

body {
  background-color: #FFFFFF !important;
}

/* Centrar los mensajes de alerta en dispositivos móviles */
@media only screen and (max-width: 768px) {
  .toast {
    left: 50%;
    transform: translateX(-50%);
  }
}
